{
  "games": [
    {
      "id": "Mike:2023-04-30:1",
      "date": {
        "year": 2023,
        "month": 4,
        "day": 30
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 28,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 48,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 68,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 88,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 108,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 126,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 134,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 143,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 152,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 152,
      "pins": 94,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.7,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 3,
        "spares": {
          "total": 3,
          "single": 2,
          "multiple": 1
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2023-04-30:2",
      "date": {
        "year": 2023,
        "month": 4,
        "day": 30
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 16,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 25,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            6
          ],
          "cumulative": 31,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 51,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 78,
          "score": 27,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 97,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 106,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 125,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "/",
            9
          ],
          "cumulative": 144,
          "score": 19,
          "pins": 19,
          "split": false,
          "spare": "single",
          "open": false
        }
      ],
      "score": 144,
      "pins": 99,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7.8,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 2,
        "spares": {
          "total": 3,
          "single": 3,
          "multiple": 0
        },
        "misses": 3,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2023-05-14:1",
      "date": {
        "year": 2023,
        "month": 5,
        "day": 14
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 19,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 28,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 48,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 67,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 76,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 106,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 133,
          "score": 27,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 152,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 161,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X",
            9,
            "/"
          ],
          "cumulative": 181,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        }
      ],
      "score": 181,
      "pins": 97,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.9,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 5,
        "spares": {
          "total": 3,
          "single": 2,
          "multiple": 1
        },
        "misses": 1,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2023-05-14:2",
      "date": {
        "year": 2023,
        "month": 5,
        "day": 14
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 19,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 28,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 36,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 53,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 73,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 93,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 113,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 132,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 141,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X",
            7,
            2
          ],
          "cumulative": 160,
          "score": 19,
          "pins": 19,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 160,
      "pins": 105,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.6,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 3,
        "spares": {
          "total": 4,
          "single": 0,
          "multiple": 4
        },
        "misses": 3,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2023-06-11:1",
      "date": {
        "year": 2023,
        "month": 6,
        "day": 11
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 18,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 38,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 66,
          "score": 28,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 85,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 94,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 124,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 153,
          "score": 29,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 172,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 181,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 181,
      "pins": 96,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 9.4,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 5,
        "spares": {
          "total": 1,
          "single": 1,
          "multiple": 0
        },
        "misses": 3,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2023-06-11:2",
      "date": {
        "year": 2023,
        "month": 6,
        "day": 11
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 19,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 39,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 69,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 99,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 129,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 156,
          "score": 27,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 175,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 184,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 192,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 201,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        }
      ],
      "score": 201,
      "pins": 96,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 9,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 5,
        "spares": {
          "total": 2,
          "single": 1,
          "multiple": 1
        },
        "misses": 1,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2023-08-27:1",
      "date": {
        "year": 2023,
        "month": 8,
        "day": 27
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "X"
          ],
          "cumulative": 28,
          "score": 28,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 47,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 56,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 65,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 82,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 92,
          "score": 10,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "-",
            8
          ],
          "cumulative": 100,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 118,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 126,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 135,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 135,
      "pins": 93,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7.6,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 2,
        "spares": {
          "total": 3,
          "single": 2,
          "multiple": 1
        },
        "misses": 3,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2023-08-27:2",
      "date": {
        "year": 2023,
        "month": 8,
        "day": 27
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 20,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 49,
          "score": 29,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 68,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 77,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 95,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 103,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 111,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 129,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 147,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            "/",
            8
          ],
          "cumulative": 165,
          "score": 18,
          "pins": 18,
          "split": false,
          "spare": "multiple",
          "open": false
        }
      ],
      "score": 165,
      "pins": 103,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.5,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 2,
        "spares": {
          "total": 5,
          "single": 2,
          "multiple": 3
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2023-09-24:1",
      "date": {
        "year": 2023,
        "month": 9,
        "day": 24
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 20,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 39,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 48,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 56,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 65,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 74,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 104,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 134,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 164,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X",
            "X",
            "X"
          ],
          "cumulative": 194,
          "score": 30,
          "pins": 30,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 194,
      "pins": 115,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.8,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 7,
        "spares": {
          "total": 1,
          "single": 1,
          "multiple": 0
        },
        "misses": 1,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2023-09-24:2",
      "date": {
        "year": 2023,
        "month": 9,
        "day": 24
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 38,
          "score": 29,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 58,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 78,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 107,
          "score": 29,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 126,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 135,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 154,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 163,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/",
            6
          ],
          "cumulative": 179,
          "score": 16,
          "pins": 16,
          "split": false,
          "spare": "multiple",
          "open": false
        }
      ],
      "score": 179,
      "pins": 103,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 9.1,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 4,
        "spares": {
          "total": 3,
          "single": 1,
          "multiple": 2
        },
        "misses": 3,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2023-10-08:1",
      "date": {
        "year": 2023,
        "month": 10,
        "day": 8
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 38,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 68,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 96,
          "score": 28,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 115,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 124,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            1
          ],
          "cumulative": 131,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 151,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 171,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X",
            "X",
            8
          ],
          "cumulative": 199,
          "score": 28,
          "pins": 28,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 199,
      "pins": 112,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 9,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 7,
        "spares": {
          "total": 1,
          "single": 1,
          "multiple": 0
        },
        "misses": 0,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2023-10-08:2",
      "date": {
        "year": 2023,
        "month": 10,
        "day": 8
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 18,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 26,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 45,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 65,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 85,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 103,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 123,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 150,
          "score": 27,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 167,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 174,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        }
      ],
      "score": 174,
      "pins": 95,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.5,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 3,
        "spares": {
          "total": 5,
          "single": 1,
          "multiple": 4
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2023-10-22:1",
      "date": {
        "year": 2023,
        "month": 10,
        "day": 22
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 17,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 36,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 45,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 64,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 73,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 81,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 101,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 120,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 129,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 129,
      "pins": 92,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.5,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 3,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2023-10-22:2",
      "date": {
        "year": 2023,
        "month": 10,
        "day": 22
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 7,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 27,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 47,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 66,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 75,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 92,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 99,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 118,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 138,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X",
            7,
            "/"
          ],
          "cumulative": 158,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        }
      ],
      "score": 158,
      "pins": 93,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 9,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 4,
        "spares": {
          "total": 4,
          "single": 3,
          "multiple": 1
        },
        "misses": 3,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2023-10-29:1",
      "date": {
        "year": 2023,
        "month": 10,
        "day": 29
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            1,
            "/"
          ],
          "cumulative": 20,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 40,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 57,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 76,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 95,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 113,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 122,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 142,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 161,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "/",
            9
          ],
          "cumulative": 180,
          "score": 19,
          "pins": 19,
          "split": false,
          "spare": "single",
          "open": false
        }
      ],
      "score": 180,
      "pins": 108,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.1,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 2,
        "spares": {
          "total": 7,
          "single": 5,
          "multiple": 2
        },
        "misses": 0,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2023-10-29:2",
      "date": {
        "year": 2023,
        "month": 10,
        "day": 29
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "X"
          ],
          "cumulative": 20,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 39,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 59,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 79,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 97,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 115,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 124,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 133,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 163,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X",
            "X",
            "X"
          ],
          "cumulative": 193,
          "score": 30,
          "pins": 30,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 193,
      "pins": 118,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.9,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 6,
        "spares": {
          "total": 4,
          "single": 2,
          "multiple": 2
        },
        "misses": 0,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2023-11-26:1",
      "date": {
        "year": 2023,
        "month": 11,
        "day": 26
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "X"
          ],
          "cumulative": 20,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 38,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 47,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 55,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 63,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 72,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 81,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 90,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 106,
          "score": 16,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 112,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 112,
      "pins": 88,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.2,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 2,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2023-11-26:2",
      "date": {
        "year": 2023,
        "month": 11,
        "day": 26
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 7,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 15,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 44,
          "score": 29,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 64,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 84,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 114,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 143,
          "score": 29,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 163,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 182,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "/",
            "X"
          ],
          "cumulative": 202,
          "score": 20,
          "pins": 20,
          "split": false,
          "spare": "single",
          "open": false
        }
      ],
      "score": 202,
      "pins": 105,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 9.2,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 6,
        "spares": {
          "total": 3,
          "single": 3,
          "multiple": 0
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2023-12-03:1",
      "date": {
        "year": 2023,
        "month": 12,
        "day": 3
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "X"
          ],
          "cumulative": 30,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 60,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 90,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 120,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 149,
          "score": 29,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 169,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 188,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 207,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 226,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 235,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 235,
      "pins": 99,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 9.6,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 6,
        "spares": {
          "total": 3,
          "single": 3,
          "multiple": 0
        },
        "misses": 1,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2023-12-03:2",
      "date": {
        "year": 2023,
        "month": 12,
        "day": 3
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "X"
          ],
          "cumulative": 30,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 60,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 89,
          "score": 29,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 108,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 117,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 146,
          "score": 29,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 165,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 174,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 193,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 202,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 202,
      "pins": 97,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 9.7,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 7,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 3,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2024-01-07:1",
      "date": {
        "year": 2024,
        "month": 1,
        "day": 7
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "X"
          ],
          "cumulative": 18,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 26,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            4
          ],
          "cumulative": 34,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 43,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 52,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 71,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 81,
          "score": 10,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "-",
            "/"
          ],
          "cumulative": 101,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 121,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/",
            "X"
          ],
          "cumulative": 141,
          "score": 20,
          "pins": 20,
          "split": false,
          "spare": "single",
          "open": false
        }
      ],
      "score": 141,
      "pins": 104,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7.7,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 3,
        "spares": {
          "total": 4,
          "single": 3,
          "multiple": 1
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2024-01-07:2",
      "date": {
        "year": 2024,
        "month": 1,
        "day": 7
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "X"
          ],
          "cumulative": 20,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 38,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 47,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 66,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 83,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 102,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 121,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 140,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 159,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "/",
            "X"
          ],
          "cumulative": 179,
          "score": 20,
          "pins": 20,
          "split": false,
          "spare": "single",
          "open": false
        }
      ],
      "score": 179,
      "pins": 109,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.6,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 2,
        "spares": {
          "total": 8,
          "single": 5,
          "multiple": 3
        },
        "misses": 0,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2024-01-14:1",
      "date": {
        "year": 2024,
        "month": 1,
        "day": 14
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 29,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 49,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 69,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 88,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 97,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 115,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 124,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 144,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X",
            9,
            "-"
          ],
          "cumulative": 163,
          "score": 19,
          "pins": 19,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 163,
      "pins": 106,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 9.1,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 3,
        "spares": {
          "total": 4,
          "single": 4,
          "multiple": 0
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2024-01-14:2",
      "date": {
        "year": 2024,
        "month": 1,
        "day": 14
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 20,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 39,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 48,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 78,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 108,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 138,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 166,
          "score": 28,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 185,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 194,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 203,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 203,
      "pins": 97,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 9.5,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 6,
        "spares": {
          "total": 1,
          "single": 1,
          "multiple": 0
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2024-03-31:1",
      "date": {
        "year": 2024,
        "month": 3,
        "day": 31
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 17,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 35,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 55,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 75,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 93,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 102,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 109,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 137,
          "score": 28,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 156,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 165,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 165,
      "pins": 95,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.6,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 3,
        "spares": {
          "total": 4,
          "single": 2,
          "multiple": 2
        },
        "misses": 1,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2024-03-31:2",
      "date": {
        "year": 2024,
        "month": 3,
        "day": 31
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "-",
            7
          ],
          "cumulative": 7,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 14,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 33,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 52,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 61,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 70,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 99,
          "score": 29,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 119,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 137,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 145,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 145,
      "pins": 90,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7.6,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 2,
        "spares": {
          "total": 3,
          "single": 2,
          "multiple": 1
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2024-03-31:3",
      "date": {
        "year": 2024,
        "month": 3,
        "day": 31
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            5,
            3
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 28,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 48,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 65,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 84,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 93,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 123,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 153,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 181,
          "score": 28,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X",
            8,
            1
          ],
          "cumulative": 200,
          "score": 19,
          "pins": 19,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 200,
      "pins": 106,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.8,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 5,
        "spares": {
          "total": 3,
          "single": 1,
          "multiple": 2
        },
        "misses": 1,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2024-05-19:1",
      "date": {
        "year": 2024,
        "month": 5,
        "day": 19
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "X"
          ],
          "cumulative": 30,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 57,
          "score": 27,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 74,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 81,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 108,
          "score": 27,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 128,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 148,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 167,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 176,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X",
            9,
            "/"
          ],
          "cumulative": 196,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        }
      ],
      "score": 196,
      "pins": 96,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 9,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 7,
        "spares": {
          "total": 2,
          "single": 1,
          "multiple": 1
        },
        "misses": 1,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2024-05-19:2",
      "date": {
        "year": 2024,
        "month": 5,
        "day": 19
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            3
          ],
          "cumulative": 15,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 44,
          "score": 29,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 63,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 72,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 80,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 99,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 108,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 138,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X",
            "X",
            7
          ],
          "cumulative": 165,
          "score": 27,
          "pins": 27,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 165,
      "pins": 108,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.5,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 6,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2024-07-07:1",
      "date": {
        "year": 2024,
        "month": 7,
        "day": 7
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 16,
          "score": 16,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 24,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 54,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 80,
          "score": 26,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 99,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 108,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 116,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 124,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 154,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X",
            "X",
            7
          ],
          "cumulative": 181,
          "score": 27,
          "pins": 27,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 181,
      "pins": 110,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.5,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 6,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 1,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2024-07-07:2",
      "date": {
        "year": 2024,
        "month": 7,
        "day": 7
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 20,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 48,
          "score": 28,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 67,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 76,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 96,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 112,
          "score": 16,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            6,
            "/"
          ],
          "cumulative": 131,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 150,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 169,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "/",
            7
          ],
          "cumulative": 186,
          "score": 17,
          "pins": 17,
          "split": false,
          "spare": "single",
          "open": false
        }
      ],
      "score": 186,
      "pins": 106,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.8,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 3,
        "spares": {
          "total": 6,
          "single": 4,
          "multiple": 2
        },
        "misses": 0,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2024-08-04:1",
      "date": {
        "year": 2024,
        "month": 8,
        "day": 4
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 17,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 24,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 44,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 64,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 82,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 102,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 122,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 138,
          "score": 16,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 144,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/",
            "X"
          ],
          "cumulative": 164,
          "score": 20,
          "pins": 20,
          "split": false,
          "spare": "single",
          "open": false
        }
      ],
      "score": 164,
      "pins": 103,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.5,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 3,
        "spares": {
          "total": 6,
          "single": 4,
          "multiple": 2
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2024-08-04:2",
      "date": {
        "year": 2024,
        "month": 8,
        "day": 4
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 28,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 37,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 57,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 74,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 81,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 101,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 117,
          "score": 16,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 126,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 135,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 135,
      "pins": 93,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.8,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 3,
        "spares": {
          "total": 2,
          "single": 2,
          "multiple": 0
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2024-12-01:1",
      "date": {
        "year": 2024,
        "month": 12,
        "day": 1
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 18,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 27,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 35,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 44,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 63,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 82,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 91,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 121,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 151,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X",
            "X",
            7
          ],
          "cumulative": 178,
          "score": 27,
          "pins": 27,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 178,
      "pins": 112,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.9,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 4,
        "spares": {
          "total": 3,
          "single": 3,
          "multiple": 0
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2024-12-01:2",
      "date": {
        "year": 2024,
        "month": 12,
        "day": 1
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 17,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 25,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            8
          ],
          "cumulative": 33,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 63,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 93,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 123,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 150,
          "score": 27,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 170,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 187,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 196,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        }
      ],
      "score": 196,
      "pins": 95,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 5,
        "spares": {
          "total": 2,
          "single": 1,
          "multiple": 1
        },
        "misses": 1,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2024-12-08:1",
      "date": {
        "year": 2024,
        "month": 12,
        "day": 8
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "X"
          ],
          "cumulative": 30,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 56,
          "score": 26,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 73,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            6,
            1
          ],
          "cumulative": 80,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 99,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 108,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 116,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 136,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 153,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 161,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 161,
      "pins": 92,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.7,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 5,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 1,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2024-12-08:2",
      "date": {
        "year": 2024,
        "month": 12,
        "day": 8
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 18,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 35,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 55,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 85,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 115,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 145,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 175,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 205,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X",
            "X",
            6
          ],
          "cumulative": 231,
          "score": 26,
          "pins": 26,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 231,
      "pins": 114,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 9.3,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 7,
        "spares": {
          "total": 2,
          "single": 1,
          "multiple": 1
        },
        "misses": 1,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2025-01-05:1",
      "date": {
        "year": 2025,
        "month": 1,
        "day": 5
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 17,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 37,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 56,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 65,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 95,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 125,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 155,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 185,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 215,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X",
            "X",
            "X"
          ],
          "cumulative": 245,
          "score": 30,
          "pins": 30,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 245,
      "pins": 119,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 9.3,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 9,
        "spares": {
          "total": 2,
          "single": 0,
          "multiple": 2
        },
        "misses": 1,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2025-01-05:2",
      "date": {
        "year": 2025,
        "month": 1,
        "day": 5
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "X"
          ],
          "cumulative": 19,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 28,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 48,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 77,
          "score": 29,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 97,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 116,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 125,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 155,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 184,
          "score": 29,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X",
            9,
            "-"
          ],
          "cumulative": 203,
          "score": 19,
          "pins": 19,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 203,
      "pins": 107,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 9.5,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 6,
        "spares": {
          "total": 2,
          "single": 1,
          "multiple": 1
        },
        "misses": 3,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2025-02-16:1",
      "date": {
        "year": 2025,
        "month": 2,
        "day": 16
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 29,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            3,
            "/"
          ],
          "cumulative": 46,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 53,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 73,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 91,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 99,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 129,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 159,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X",
            "X",
            8
          ],
          "cumulative": 187,
          "score": 28,
          "pins": 28,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 187,
      "pins": 112,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.5,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 6,
        "spares": {
          "total": 2,
          "single": 1,
          "multiple": 1
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Mike:2025-02-16:2",
      "date": {
        "year": 2025,
        "month": 2,
        "day": 16
      },
      "user": "Mike",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "X"
          ],
          "cumulative": 27,
          "score": 27,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 44,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 51,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 69,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 78,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 98,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 128,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 158,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 188,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X",
            "X",
            6
          ],
          "cumulative": 214,
          "score": 26,
          "pins": 26,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 214,
      "pins": 112,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 9.3,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 7,
        "spares": {
          "total": 2,
          "single": 2,
          "multiple": 0
        },
        "misses": 1,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-04-30:1",
      "date": {
        "year": 2023,
        "month": 4,
        "day": 30
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "-",
            "-"
          ],
          "cumulative": 0,
          "score": 0,
          "pins": 0,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            6
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            "-"
          ],
          "cumulative": 9,
          "score": 0,
          "pins": 0,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 29,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 47,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 56,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            "-"
          ],
          "cumulative": 61,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 70,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            8
          ],
          "cumulative": 78,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 85,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        }
      ],
      "score": 85,
      "pins": 67,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 1,
        "spares": {
          "total": 1,
          "single": 1,
          "multiple": 0
        },
        "misses": 7,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-04-30:2",
      "date": {
        "year": 2023,
        "month": 4,
        "day": 30
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 10,
          "score": 10,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "-",
            9
          ],
          "cumulative": 19,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 26,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            9
          ],
          "cumulative": 35,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 63,
          "score": 28,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 83,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 103,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 123,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 133,
          "score": 10,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "-",
            9
          ],
          "cumulative": 142,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 142,
      "pins": 94,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.9,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 3,
        "spares": {
          "total": 3,
          "single": 0,
          "multiple": 3
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-05-07:1",
      "date": {
        "year": 2023,
        "month": 5,
        "day": 7
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            5,
            "-"
          ],
          "cumulative": 5,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 12,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 21,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 30,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 39,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "/"
          ],
          "cumulative": 55,
          "score": 16,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            6,
            "/"
          ],
          "cumulative": 71,
          "score": 16,
          "pins": 10,
          "split": "converted",
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 80,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 90,
          "score": 10,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "-",
            9
          ],
          "cumulative": 99,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 99,
      "pins": 87,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6.2,
        "splits": {
          "total": 1,
          "converted": 1
        },
        "strikes": 0,
        "spares": {
          "total": 3,
          "single": 0,
          "multiple": 3
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-05-07:2",
      "date": {
        "year": 2023,
        "month": 5,
        "day": 7
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            1,
            8
          ],
          "cumulative": 18,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 26,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            8
          ],
          "cumulative": 34,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 54,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            3,
            "/"
          ],
          "cumulative": 73,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 82,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 100,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 110,
          "score": 10,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "-",
            1
          ],
          "cumulative": 111,
          "score": 1,
          "pins": 1,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 111,
      "pins": 84,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.5,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 1,
        "spares": {
          "total": 3,
          "single": 0,
          "multiple": 3
        },
        "misses": 5,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-05-14:1",
      "date": {
        "year": 2023,
        "month": 5,
        "day": 14
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "-",
            "-"
          ],
          "cumulative": 0,
          "score": 0,
          "pins": 0,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 17,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            5
          ],
          "cumulative": 22,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 29,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            1,
            8
          ],
          "cumulative": 38,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 46,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            7
          ],
          "cumulative": 53,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            "-"
          ],
          "cumulative": 53,
          "score": 0,
          "pins": 0,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 62,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 62,
      "pins": 62,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 4,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 10,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-05-14:2",
      "date": {
        "year": 2023,
        "month": 5,
        "day": 14
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 19,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 28,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            "-"
          ],
          "cumulative": 28,
          "score": 0,
          "pins": 0,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            7
          ],
          "cumulative": 35,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "-"
          ],
          "cumulative": 38,
          "score": 3,
          "pins": 3,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 47,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 56,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            8
          ],
          "cumulative": 64,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 82,
          "score": 18,
          "pins": 10,
          "split": "converted",
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 90,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 90,
      "pins": 73,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.4,
        "splits": {
          "total": 1,
          "converted": 1
        },
        "strikes": 0,
        "spares": {
          "total": 2,
          "single": 1,
          "multiple": 1
        },
        "misses": 8,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-05-16:1",
      "date": {
        "year": 2023,
        "month": 5,
        "day": 16
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 18,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 27,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 36,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            6
          ],
          "cumulative": 42,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 49,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 57,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 66,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            "-"
          ],
          "cumulative": 71,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            1
          ],
          "cumulative": 72,
          "score": 1,
          "pins": 1,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 72,
      "pins": 72,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 8,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-05-16:2",
      "date": {
        "year": 2023,
        "month": 5,
        "day": 16
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 6,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 35,
          "score": 29,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 54,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 63,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 72,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            "/"
          ],
          "cumulative": 91,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 111,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 137,
          "score": 26,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 153,
          "score": 16,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 159,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 159,
      "pins": 90,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.4,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 4,
        "spares": {
          "total": 2,
          "single": 1,
          "multiple": 1
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-06-11:1",
      "date": {
        "year": 2023,
        "month": 6,
        "day": 11
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 18,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 38,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 58,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 86,
          "score": 28,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 105,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 114,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 123,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 141,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 150,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 150,
      "pins": 95,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 9,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 3,
        "spares": {
          "total": 2,
          "single": 1,
          "multiple": 1
        },
        "misses": 3,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-06-11:2",
      "date": {
        "year": 2023,
        "month": 6,
        "day": 11
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 16,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            1,
            6
          ],
          "cumulative": 23,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 32,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 51,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "-",
            9
          ],
          "cumulative": 60,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 79,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 98,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 107,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/",
            9
          ],
          "cumulative": 126,
          "score": 19,
          "pins": 19,
          "split": false,
          "spare": "multiple",
          "open": false
        }
      ],
      "score": 126,
      "pins": 99,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6.9,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 1,
        "spares": {
          "total": 3,
          "single": 1,
          "multiple": 2
        },
        "misses": 5,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-08-27:1",
      "date": {
        "year": 2023,
        "month": 8,
        "day": 27
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 17,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 25,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 34,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 42,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 59,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 66,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 81,
          "score": 15,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            5,
            "-"
          ],
          "cumulative": 86,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/",
            9
          ],
          "cumulative": 105,
          "score": 19,
          "pins": 19,
          "split": false,
          "spare": "multiple",
          "open": false
        }
      ],
      "score": 105,
      "pins": 93,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 2,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 6,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-08-27:2",
      "date": {
        "year": 2023,
        "month": 8,
        "day": 27
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 29,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 46,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 54,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 83,
          "score": 29,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 103,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 121,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 138,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 145,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 153,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 153,
      "pins": 92,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.5,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 3,
        "spares": {
          "total": 3,
          "single": 1,
          "multiple": 2
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-09-24:1",
      "date": {
        "year": 2023,
        "month": 9,
        "day": 24
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "-",
            9
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 17,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            9
          ],
          "cumulative": 26,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 35,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 41,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 50,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 70,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 80,
          "score": 10,
          "pins": 10,
          "split": "converted",
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "-",
            9
          ],
          "cumulative": 89,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 96,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        }
      ],
      "score": 96,
      "pins": 86,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.7,
        "splits": {
          "total": 2,
          "converted": 1
        },
        "strikes": 1,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 8,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-09-24:2",
      "date": {
        "year": 2023,
        "month": 9,
        "day": 24
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 16,
          "score": 16,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 22,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 30,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 39,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            3
          ],
          "cumulative": 45,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 52,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 59,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 68,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 77,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "-"
          ],
          "cumulative": 80,
          "score": 3,
          "pins": 3,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 80,
      "pins": 74,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6.7,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 7,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-10-08:1",
      "date": {
        "year": 2023,
        "month": 10,
        "day": 8
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 18,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            5
          ],
          "cumulative": 26,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 34,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 43,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 52,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 61,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 70,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 90,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/",
            8
          ],
          "cumulative": 108,
          "score": 18,
          "pins": 18,
          "split": false,
          "spare": "single",
          "open": false
        }
      ],
      "score": 108,
      "pins": 98,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.1,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 1,
        "spares": {
          "total": 1,
          "single": 1,
          "multiple": 0
        },
        "misses": 5,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-10-08:2",
      "date": {
        "year": 2023,
        "month": 10,
        "day": 8
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            "/"
          ],
          "cumulative": 18,
          "score": 10,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "-",
            7
          ],
          "cumulative": 25,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            "/"
          ],
          "cumulative": 44,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 53,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            4
          ],
          "cumulative": 62,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 71,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 80,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 89,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 98,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 98,
      "pins": 89,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6.2,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 2,
          "single": 0,
          "multiple": 2
        },
        "misses": 7,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-10-29:1",
      "date": {
        "year": 2023,
        "month": 10,
        "day": 29
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "X"
          ],
          "cumulative": 19,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "-",
            9
          ],
          "cumulative": 28,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 46,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 55,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            1,
            "-"
          ],
          "cumulative": 56,
          "score": 1,
          "pins": 1,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 65,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 74,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 94,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 111,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 118,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 118,
      "pins": 84,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6.7,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 2,
        "spares": {
          "total": 2,
          "single": 0,
          "multiple": 2
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-10-29:2",
      "date": {
        "year": 2023,
        "month": 10,
        "day": 29
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 17,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 25,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 32,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 51,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 60,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 80,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 99,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 108,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 117,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "/",
            8
          ],
          "cumulative": 135,
          "score": 18,
          "pins": 18,
          "split": false,
          "spare": "multiple",
          "open": false
        }
      ],
      "score": 135,
      "pins": 100,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 2,
        "spares": {
          "total": 3,
          "single": 1,
          "multiple": 2
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-11-26:1",
      "date": {
        "year": 2023,
        "month": 11,
        "day": 26
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 18,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 27,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 36,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 45,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 75,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 103,
          "score": 28,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 122,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 131,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X",
            8,
            "/"
          ],
          "cumulative": 151,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        }
      ],
      "score": 151,
      "pins": 94,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 9.1,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 4,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2023-11-26:2",
      "date": {
        "year": 2023,
        "month": 11,
        "day": 26
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            1,
            8
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 27,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 35,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 43,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 61,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 69,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 77,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            5
          ],
          "cumulative": 85,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 93,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/",
            4
          ],
          "cumulative": 107,
          "score": 14,
          "pins": 14,
          "split": false,
          "spare": "single",
          "open": false
        }
      ],
      "score": 107,
      "pins": 91,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7.1,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 1,
        "spares": {
          "total": 2,
          "single": 1,
          "multiple": 1
        },
        "misses": 5,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2024-01-07:1",
      "date": {
        "year": 2024,
        "month": 1,
        "day": 7
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 29,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 48,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 57,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 76,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 85,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 102,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 110,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 139,
          "score": 29,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X",
            9,
            "-"
          ],
          "cumulative": 158,
          "score": 19,
          "pins": 19,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 158,
      "pins": 104,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.8,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 3,
        "spares": {
          "total": 3,
          "single": 1,
          "multiple": 2
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2024-01-07:2",
      "date": {
        "year": 2024,
        "month": 1,
        "day": 7
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 27,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 35,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            "-"
          ],
          "cumulative": 35,
          "score": 0,
          "pins": 0,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 53,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 61,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 70,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 88,
          "score": 18,
          "pins": 10,
          "split": "converted",
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 96,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 104,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 104,
      "pins": 80,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7.8,
        "splits": {
          "total": 2,
          "converted": 1
        },
        "strikes": 2,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 8,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2024-01-14:1",
      "date": {
        "year": 2024,
        "month": 1,
        "day": 14
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 25,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 44,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 53,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 62,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 70,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            1
          ],
          "cumulative": 71,
          "score": 1,
          "pins": 1,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 90,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 99,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "/",
            9
          ],
          "cumulative": 118,
          "score": 19,
          "pins": 19,
          "split": false,
          "spare": "multiple",
          "open": false
        }
      ],
      "score": 118,
      "pins": 93,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7.2,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 1,
        "spares": {
          "total": 3,
          "single": 1,
          "multiple": 2
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2024-01-14:2",
      "date": {
        "year": 2024,
        "month": 1,
        "day": 14
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "X"
          ],
          "cumulative": 19,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 28,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 37,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 46,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 63,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 71,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            1,
            8
          ],
          "cumulative": 80,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 87,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 106,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 115,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 115,
      "pins": 90,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7.9,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 2,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 5,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2024-03-31:1",
      "date": {
        "year": 2024,
        "month": 3,
        "day": 31
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 17,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 36,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 45,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 65,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 92,
          "score": 27,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 111,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 120,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 138,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 147,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 147,
      "pins": 94,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.6,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 3,
        "spares": {
          "total": 2,
          "single": 2,
          "multiple": 0
        },
        "misses": 1,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2024-03-31:2",
      "date": {
        "year": 2024,
        "month": 3,
        "day": 31
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 27,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 35,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 52,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 72,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 91,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 100,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 129,
          "score": 29,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 149,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/",
            8
          ],
          "cumulative": 167,
          "score": 18,
          "pins": 18,
          "split": false,
          "spare": "single",
          "open": false
        }
      ],
      "score": 167,
      "pins": 104,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.5,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 3,
        "spares": {
          "total": 4,
          "single": 2,
          "multiple": 2
        },
        "misses": 1,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2024-05-19:1",
      "date": {
        "year": 2024,
        "month": 5,
        "day": 19
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 18,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 26,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 34,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 51,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 60,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 78,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 87,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            4
          ],
          "cumulative": 91,
          "score": 4,
          "pins": 4,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 100,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/",
            8
          ],
          "cumulative": 118,
          "score": 18,
          "pins": 18,
          "split": false,
          "spare": "single",
          "open": false
        }
      ],
      "score": 118,
      "pins": 95,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7.3,
        "splits": {
          "total": 3,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 4,
          "single": 2,
          "multiple": 2
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2024-05-19:2",
      "date": {
        "year": 2024,
        "month": 5,
        "day": 19
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 16,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 34,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 42,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            "/"
          ],
          "cumulative": 60,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 68,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 88,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 107,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 126,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 135,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 135,
      "pins": 91,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.4,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 2,
        "spares": {
          "total": 3,
          "single": 2,
          "multiple": 1
        },
        "misses": 5,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2024-07-14:1",
      "date": {
        "year": 2024,
        "month": 7,
        "day": 14
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            1,
            7
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            6
          ],
          "cumulative": 17,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            8
          ],
          "cumulative": 25,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 34,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 43,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 52,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 59,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 68,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 77,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 86,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 86,
      "pins": 86,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.8,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2024-07-14:2",
      "date": {
        "year": 2024,
        "month": 7,
        "day": 14
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            4,
            1
          ],
          "cumulative": 5,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 14,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 23,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            3
          ],
          "cumulative": 29,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 47,
          "score": 18,
          "pins": 10,
          "split": "converted",
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 55,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 64,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 83,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 92,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 101,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 101,
      "pins": 84,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7.6,
        "splits": {
          "total": 2,
          "converted": 1
        },
        "strikes": 1,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2024-08-04:1",
      "date": {
        "year": 2024,
        "month": 8,
        "day": 4
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 27,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 43,
          "score": 16,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            6,
            "/"
          ],
          "cumulative": 61,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 77,
          "score": 16,
          "pins": 10,
          "split": "converted",
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            6,
            "/"
          ],
          "cumulative": 97,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 115,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 123,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            1,
            8
          ],
          "cumulative": 132,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 140,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 140,
      "pins": 94,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6.9,
        "splits": {
          "total": 1,
          "converted": 1
        },
        "strikes": 1,
        "spares": {
          "total": 5,
          "single": 0,
          "multiple": 5
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2024-08-04:2",
      "date": {
        "year": 2024,
        "month": 8,
        "day": 4
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            5,
            3
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 23,
          "score": 15,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            5,
            "/"
          ],
          "cumulative": 42,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 51,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 59,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 68,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 77,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 86,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 95,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X",
            "X",
            3
          ],
          "cumulative": 118,
          "score": 23,
          "pins": 23,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 118,
      "pins": 104,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7.5,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 2,
        "spares": {
          "total": 2,
          "single": 0,
          "multiple": 2
        },
        "misses": 3,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2024-12-01:1",
      "date": {
        "year": 2024,
        "month": 12,
        "day": 1
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 27,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 35,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 53,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 61,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 70,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            9
          ],
          "cumulative": 79,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 98,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 107,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/",
            7
          ],
          "cumulative": 124,
          "score": 17,
          "pins": 17,
          "split": false,
          "spare": "single",
          "open": false
        }
      ],
      "score": 124,
      "pins": 99,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7.7,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 4,
          "single": 3,
          "multiple": 1
        },
        "misses": 6,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2024-12-01:2",
      "date": {
        "year": 2024,
        "month": 12,
        "day": 1
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "X"
          ],
          "cumulative": 29,
          "score": 29,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 48,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 57,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 66,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 75,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 84,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 102,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 110,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 140,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X",
            "X",
            "X"
          ],
          "cumulative": 170,
          "score": 30,
          "pins": 30,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 170,
      "pins": 114,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 9.2,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 7,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 3,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2024-12-08:1",
      "date": {
        "year": 2024,
        "month": 12,
        "day": 8
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 18,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            9
          ],
          "cumulative": 27,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "-",
            "/"
          ],
          "cumulative": 47,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 67,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 86,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 95,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 103,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 111,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/",
            8
          ],
          "cumulative": 129,
          "score": 18,
          "pins": 18,
          "split": false,
          "spare": "single",
          "open": false
        }
      ],
      "score": 129,
      "pins": 100,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 1,
        "spares": {
          "total": 3,
          "single": 2,
          "multiple": 1
        },
        "misses": 6,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2024-12-08:2",
      "date": {
        "year": 2024,
        "month": 12,
        "day": 8
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 16,
          "score": 16,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 22,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 41,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 50,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 58,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 75,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 82,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 90,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 103,
          "score": 13,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            3,
            6
          ],
          "cumulative": 112,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 112,
      "pins": 87,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7.4,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 1,
        "spares": {
          "total": 3,
          "single": 0,
          "multiple": 3
        },
        "misses": 5,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2025-01-05:1",
      "date": {
        "year": 2025,
        "month": 1,
        "day": 5
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            3,
            4
          ],
          "cumulative": 7,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 27,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 46,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 55,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 63,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 71,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 79,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 109,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 139,
          "score": 30,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X",
            "X",
            9
          ],
          "cumulative": 168,
          "score": 29,
          "pins": 29,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 168,
      "pins": 109,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.3,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 5,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2025-01-05:2",
      "date": {
        "year": 2025,
        "month": 1,
        "day": 5
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 19,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 28,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 37,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 46,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 55,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 72,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 79,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 96,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 116,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X",
            8,
            "-"
          ],
          "cumulative": 134,
          "score": 18,
          "pins": 18,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 134,
      "pins": 101,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.8,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 2,
        "spares": {
          "total": 3,
          "single": 2,
          "multiple": 1
        },
        "misses": 6,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2025-02-16:1",
      "date": {
        "year": 2025,
        "month": 2,
        "day": 16
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 19,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 38,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 47,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 65,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 74,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "/"
          ],
          "cumulative": 93,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 102,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 111,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 120,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/",
            9
          ],
          "cumulative": 139,
          "score": 19,
          "pins": 19,
          "split": false,
          "spare": "single",
          "open": false
        }
      ],
      "score": 139,
      "pins": 104,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 8.1,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 5,
          "single": 3,
          "multiple": 2
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Sara:2025-02-16:2",
      "date": {
        "year": 2025,
        "month": 2,
        "day": 16
      },
      "user": "Sara",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "X"
          ],
          "cumulative": 16,
          "score": 16,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 22,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 40,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 49,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 62,
          "score": 13,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            3,
            "/"
          ],
          "cumulative": 80,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 96,
          "score": 16,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 104,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 113,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/",
            8
          ],
          "cumulative": 131,
          "score": 18,
          "pins": 18,
          "split": false,
          "spare": "multiple",
          "open": false
        }
      ],
      "score": 131,
      "pins": 100,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7.2,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 1,
        "spares": {
          "total": 5,
          "single": 0,
          "multiple": 5
        },
        "misses": 1,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-04-30:1",
      "date": {
        "year": 2023,
        "month": 4,
        "day": 30
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            1,
            3
          ],
          "cumulative": 4,
          "score": 4,
          "pins": 4,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            3
          ],
          "cumulative": 11,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 19,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            1
          ],
          "cumulative": 26,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            3
          ],
          "cumulative": 33,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 42,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            3
          ],
          "cumulative": 48,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 56,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            1
          ],
          "cumulative": 62,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "-"
          ],
          "cumulative": 65,
          "score": 3,
          "pins": 3,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 65,
      "pins": 65,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.1,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-04-30:2",
      "date": {
        "year": 2023,
        "month": 4,
        "day": 30
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 7,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            5
          ],
          "cumulative": 15,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 24,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "/"
          ],
          "cumulative": 41,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 48,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "/"
          ],
          "cumulative": 66,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 75,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            1
          ],
          "cumulative": 82,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            5
          ],
          "cumulative": 90,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 97,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 97,
      "pins": 82,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6.1,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 2,
          "single": 0,
          "multiple": 2
        },
        "misses": 3,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-05-07:1",
      "date": {
        "year": 2023,
        "month": 5,
        "day": 7
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 16,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 24,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            "-"
          ],
          "cumulative": 28,
          "score": 4,
          "pins": 4,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 47,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 56,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 64,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            2
          ],
          "cumulative": 71,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 79,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            1
          ],
          "cumulative": 86,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        }
      ],
      "score": 86,
      "pins": 77,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 6,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-05-07:2",
      "date": {
        "year": 2023,
        "month": 5,
        "day": 7
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            4,
            4
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            5
          ],
          "cumulative": 16,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            1,
            7
          ],
          "cumulative": 24,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 33,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            6
          ],
          "cumulative": 42,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            2
          ],
          "cumulative": 47,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            1
          ],
          "cumulative": 53,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 59,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 65,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            1
          ],
          "cumulative": 72,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 72,
      "pins": 72,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 4.3,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-05-14:1",
      "date": {
        "year": 2023,
        "month": 5,
        "day": 14
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            3,
            5
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            4
          ],
          "cumulative": 16,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            1
          ],
          "cumulative": 22,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            3
          ],
          "cumulative": 29,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 37,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            4
          ],
          "cumulative": 44,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 52,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            "-"
          ],
          "cumulative": 56,
          "score": 4,
          "pins": 4,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 64,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            2
          ],
          "cumulative": 71,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 71,
      "pins": 71,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 4.9,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-05-14:2",
      "date": {
        "year": 2023,
        "month": 5,
        "day": 14
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            3
          ],
          "cumulative": 14,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 22,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "-"
          ],
          "cumulative": 25,
          "score": 3,
          "pins": 3,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 32,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 38,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 44,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 51,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 64,
          "score": 13,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            3,
            6
          ],
          "cumulative": 73,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 73,
      "pins": 70,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.5,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 5,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-06-11:1",
      "date": {
        "year": 2023,
        "month": 6,
        "day": 11
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            5,
            2
          ],
          "cumulative": 7,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 16,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "-"
          ],
          "cumulative": 19,
          "score": 3,
          "pins": 3,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 27,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            3
          ],
          "cumulative": 33,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 39,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 46,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 65,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 74,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/",
            8
          ],
          "cumulative": 92,
          "score": 18,
          "pins": 18,
          "split": false,
          "spare": "multiple",
          "open": false
        }
      ],
      "score": 92,
      "pins": 83,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6.3,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 2,
          "single": 0,
          "multiple": 2
        },
        "misses": 5,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-06-11:2",
      "date": {
        "year": 2023,
        "month": 6,
        "day": 11
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "X"
          ],
          "cumulative": 19,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 28,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            4
          ],
          "cumulative": 36,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 44,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 52,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 60,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 68,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 76,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 83,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 92,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 92,
      "pins": 83,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7.3,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 1,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 5,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-08-27:1",
      "date": {
        "year": 2023,
        "month": 8,
        "day": 27
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 6,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            4
          ],
          "cumulative": 14,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            2
          ],
          "cumulative": 20,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 28,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 36,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            2
          ],
          "cumulative": 43,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 51,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 60,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            1
          ],
          "cumulative": 67,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 76,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        }
      ],
      "score": 76,
      "pins": 76,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.9,
        "splits": {
          "total": 3,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-08-27:2",
      "date": {
        "year": 2023,
        "month": 8,
        "day": 27
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            6
          ],
          "cumulative": 17,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            "/"
          ],
          "cumulative": 30,
          "score": 13,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            3,
            5
          ],
          "cumulative": 38,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            2
          ],
          "cumulative": 44,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            6
          ],
          "cumulative": 53,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 62,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            "-"
          ],
          "cumulative": 67,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 75,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/",
            7
          ],
          "cumulative": 92,
          "score": 17,
          "pins": 17,
          "split": false,
          "spare": "multiple",
          "open": false
        }
      ],
      "score": 92,
      "pins": 89,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.2,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 2,
          "single": 0,
          "multiple": 2
        },
        "misses": 1,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-09-24:1",
      "date": {
        "year": 2023,
        "month": 9,
        "day": 24
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            3,
            4
          ],
          "cumulative": 7,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            6
          ],
          "cumulative": 16,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 22,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 37,
          "score": 15,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            5,
            4
          ],
          "cumulative": 46,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            4
          ],
          "cumulative": 55,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 70,
          "score": 15,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            5,
            3
          ],
          "cumulative": 78,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            3
          ],
          "cumulative": 85,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X",
            9,
            "/"
          ],
          "cumulative": 105,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        }
      ],
      "score": 105,
      "pins": 85,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.6,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 1,
        "spares": {
          "total": 3,
          "single": 1,
          "multiple": 2
        },
        "misses": 1,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-09-24:2",
      "date": {
        "year": 2023,
        "month": 9,
        "day": 24
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 7,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 16,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "/"
          ],
          "cumulative": 29,
          "score": 13,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            3,
            6
          ],
          "cumulative": 38,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 45,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            6
          ],
          "cumulative": 54,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 61,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 67,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 75,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "/",
            7
          ],
          "cumulative": 92,
          "score": 17,
          "pins": 17,
          "split": false,
          "spare": "multiple",
          "open": false
        }
      ],
      "score": 92,
      "pins": 89,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.3,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 2,
          "single": 0,
          "multiple": 2
        },
        "misses": 5,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-10-08:1",
      "date": {
        "year": 2023,
        "month": 10,
        "day": 8
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            3,
            4
          ],
          "cumulative": 7,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 16,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 25,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 32,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 41,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            1
          ],
          "cumulative": 46,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 52,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 60,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            2
          ],
          "cumulative": 67,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 74,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 74,
      "pins": 74,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 3,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-10-08:2",
      "date": {
        "year": 2023,
        "month": 10,
        "day": 8
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            4
          ],
          "cumulative": 18,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 25,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 32,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            5
          ],
          "cumulative": 40,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            1
          ],
          "cumulative": 46,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            2,
            5
          ],
          "cumulative": 53,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            3
          ],
          "cumulative": 59,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            3
          ],
          "cumulative": 66,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 75,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 75,
      "pins": 75,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.2,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 3,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-10-22:1",
      "date": {
        "year": 2023,
        "month": 10,
        "day": 22
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 26,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 35,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            1
          ],
          "cumulative": 41,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            4
          ],
          "cumulative": 50,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 59,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 67,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 76,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            3
          ],
          "cumulative": 83,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 92,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 92,
      "pins": 85,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 1,
          "single": 1,
          "multiple": 0
        },
        "misses": 3,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-10-22:2",
      "date": {
        "year": 2023,
        "month": 10,
        "day": 22
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            1
          ],
          "cumulative": 15,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 21,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 30,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 45,
          "score": 15,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            5,
            "-"
          ],
          "cumulative": 50,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 58,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            6
          ],
          "cumulative": 67,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "-"
          ],
          "cumulative": 70,
          "score": 3,
          "pins": 3,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 78,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 78,
      "pins": 73,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 1,
          "single": 1,
          "multiple": 0
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-10-29:1",
      "date": {
        "year": 2023,
        "month": 10,
        "day": 29
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            3,
            "-"
          ],
          "cumulative": 3,
          "score": 3,
          "pins": 3,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 11,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            3
          ],
          "cumulative": 19,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 27,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 34,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            1,
            7
          ],
          "cumulative": 42,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 49,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            1
          ],
          "cumulative": 55,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 62,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            1,
            5
          ],
          "cumulative": 68,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 68,
      "pins": 68,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5,
        "splits": {
          "total": 4,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 5,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-10-29:2",
      "date": {
        "year": 2023,
        "month": 10,
        "day": 29
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            3,
            "-"
          ],
          "cumulative": 3,
          "score": 3,
          "pins": 3,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            "/"
          ],
          "cumulative": 20,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 27,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            3
          ],
          "cumulative": 35,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            5
          ],
          "cumulative": 43,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 59,
          "score": 16,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            3,
            3
          ],
          "cumulative": 65,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            "-"
          ],
          "cumulative": 70,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 77,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            1,
            4
          ],
          "cumulative": 82,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 82,
      "pins": 69,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 4.8,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 1,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-11-26:1",
      "date": {
        "year": 2023,
        "month": 11,
        "day": 26
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            6,
            1
          ],
          "cumulative": 7,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            "-"
          ],
          "cumulative": 12,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 32,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 48,
          "score": 16,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            3,
            3
          ],
          "cumulative": 54,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 61,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 67,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "-"
          ],
          "cumulative": 70,
          "score": 3,
          "pins": 3,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 77,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            6
          ],
          "cumulative": 86,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 86,
      "pins": 70,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.7,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 1,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 5,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-11-26:2",
      "date": {
        "year": 2023,
        "month": 11,
        "day": 26
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            5,
            2
          ],
          "cumulative": 7,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            "-"
          ],
          "cumulative": 12,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 19,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 28,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 36,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            4
          ],
          "cumulative": 43,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            4
          ],
          "cumulative": 50,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 59,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 65,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            2
          ],
          "cumulative": 71,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 71,
      "pins": 71,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.7,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-12-03:1",
      "date": {
        "year": 2023,
        "month": 12,
        "day": 3
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            1
          ],
          "cumulative": 15,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 21,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 29,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            6
          ],
          "cumulative": 38,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 44,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 50,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 59,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 67,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "-"
          ],
          "cumulative": 70,
          "score": 3,
          "pins": 3,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 70,
      "pins": 70,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 6,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2023-12-03:2",
      "date": {
        "year": 2023,
        "month": 12,
        "day": 3
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 15,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            1,
            2
          ],
          "cumulative": 18,
          "score": 3,
          "pins": 3,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            5
          ],
          "cumulative": 26,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            3
          ],
          "cumulative": 34,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            2,
            "-"
          ],
          "cumulative": 36,
          "score": 2,
          "pins": 2,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 42,
          "score": 6,
          "pins": 6,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "-"
          ],
          "cumulative": 45,
          "score": 3,
          "pins": 3,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 54,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/",
            7
          ],
          "cumulative": 71,
          "score": 17,
          "pins": 17,
          "split": false,
          "spare": "single",
          "open": false
        }
      ],
      "score": 71,
      "pins": 71,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.1,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 1,
          "single": 1,
          "multiple": 0
        },
        "misses": 5,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2024-01-07:1",
      "date": {
        "year": 2024,
        "month": 1,
        "day": 7
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            5,
            2
          ],
          "cumulative": 7,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "/"
          ],
          "cumulative": 23,
          "score": 16,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 29,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 38,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            5
          ],
          "cumulative": 46,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            1
          ],
          "cumulative": 52,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 60,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            1
          ],
          "cumulative": 64,
          "score": 4,
          "pins": 4,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 73,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 81,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 81,
      "pins": 75,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.1,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2024-01-07:2",
      "date": {
        "year": 2024,
        "month": 1,
        "day": 7
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 16,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 22,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            4
          ],
          "cumulative": 29,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 37,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 46,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 55,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            1
          ],
          "cumulative": 62,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 71,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 80,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 80,
      "pins": 80,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6.3,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2024-01-14:1",
      "date": {
        "year": 2024,
        "month": 1,
        "day": 14
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            4,
            "-"
          ],
          "cumulative": 4,
          "score": 4,
          "pins": 4,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            2
          ],
          "cumulative": 11,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            2
          ],
          "cumulative": 18,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            3
          ],
          "cumulative": 26,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            2
          ],
          "cumulative": 32,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            1
          ],
          "cumulative": 39,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            2
          ],
          "cumulative": 44,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            1
          ],
          "cumulative": 51,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            2,
            1
          ],
          "cumulative": 54,
          "score": 3,
          "pins": 3,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            3
          ],
          "cumulative": 62,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 62,
      "pins": 62,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 4.5,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 1,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2024-01-14:2",
      "date": {
        "year": 2024,
        "month": 1,
        "day": 14
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            5,
            1
          ],
          "cumulative": 6,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 21,
          "score": 15,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            5,
            3
          ],
          "cumulative": 29,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            1
          ],
          "cumulative": 36,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 44,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            2
          ],
          "cumulative": 50,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 66,
          "score": 16,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            6,
            1
          ],
          "cumulative": 73,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 81,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            1,
            7
          ],
          "cumulative": 89,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 89,
      "pins": 78,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.7,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 2,
          "single": 0,
          "multiple": 2
        },
        "misses": 0,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2024-03-31:1",
      "date": {
        "year": 2024,
        "month": 3,
        "day": 31
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            5,
            1
          ],
          "cumulative": 6,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            3
          ],
          "cumulative": 14,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            2
          ],
          "cumulative": 21,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 30,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 36,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            5
          ],
          "cumulative": 44,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            2
          ],
          "cumulative": 51,
          "score": 7,
          "pins": 7,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "-"
          ],
          "cumulative": 54,
          "score": 3,
          "pins": 3,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            "-"
          ],
          "cumulative": 58,
          "score": 4,
          "pins": 4,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "/",
            "X"
          ],
          "cumulative": 78,
          "score": 20,
          "pins": 20,
          "split": false,
          "spare": "multiple",
          "open": false
        }
      ],
      "score": 78,
      "pins": 78,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 4.7,
        "splits": {
          "total": 3,
          "converted": 0
        },
        "strikes": 1,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 3,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2024-03-31:2",
      "date": {
        "year": 2024,
        "month": 3,
        "day": 31
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            3
          ],
          "cumulative": 17,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            2
          ],
          "cumulative": 23,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 32,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            4
          ],
          "cumulative": 39,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 66,
          "score": 27,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 86,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 103,
          "score": 17,
          "pins": 10,
          "split": "converted",
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 120,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 129,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 129,
      "pins": 88,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7.1,
        "splits": {
          "total": 1,
          "converted": 1
        },
        "strikes": 2,
        "spares": {
          "total": 2,
          "single": 0,
          "multiple": 2
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2024-03-31:3",
      "date": {
        "year": 2024,
        "month": 3,
        "day": 31
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 8,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 17,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 25,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 44,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 53,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 68,
          "score": 15,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            5,
            "-"
          ],
          "cumulative": 73,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 81,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 90,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            4
          ],
          "cumulative": 97,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 97,
      "pins": 83,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6.6,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 1,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2024-07-07:1",
      "date": {
        "year": 2024,
        "month": 7,
        "day": 7
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            4,
            2
          ],
          "cumulative": 6,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            2
          ],
          "cumulative": 13,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 21,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            3
          ],
          "cumulative": 29,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 36,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "-"
          ],
          "cumulative": 39,
          "score": 3,
          "pins": 3,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 52,
          "score": 13,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            3,
            3
          ],
          "cumulative": 58,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 71,
          "score": 13,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            3,
            4
          ],
          "cumulative": 78,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 78,
      "pins": 72,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.2,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 2,
          "single": 0,
          "multiple": 2
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2024-07-07:2",
      "date": {
        "year": 2024,
        "month": 7,
        "day": 7
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            3,
            6
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            3
          ],
          "cumulative": 17,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            1
          ],
          "cumulative": 23,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "/"
          ],
          "cumulative": 39,
          "score": 16,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 48,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 54,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            1
          ],
          "cumulative": 59,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            1,
            "/"
          ],
          "cumulative": 76,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 91,
          "score": 15,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            5,
            "-"
          ],
          "cumulative": 96,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 96,
      "pins": 78,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 4.5,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 3,
          "single": 0,
          "multiple": 3
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2024-08-04:1",
      "date": {
        "year": 2024,
        "month": 8,
        "day": 4
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 17,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 44,
          "score": 27,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 62,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 70,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            "-"
          ],
          "cumulative": 75,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 83,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 92,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 100,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            4
          ],
          "cumulative": 107,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 107,
      "pins": 82,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 7.5,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 2,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 4,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2024-08-04:2",
      "date": {
        "year": 2024,
        "month": 8,
        "day": 4
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            3
          ],
          "cumulative": 15,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 21,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            3
          ],
          "cumulative": 27,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            5
          ],
          "cumulative": 35,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            4
          ],
          "cumulative": 43,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            3
          ],
          "cumulative": 49,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 58,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 75,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 84,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 84,
      "pins": 77,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.4,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 3,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2024-12-01:1",
      "date": {
        "year": 2024,
        "month": 12,
        "day": 1
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 7,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 24,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 40,
          "score": 16,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 46,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "/"
          ],
          "cumulative": 59,
          "score": 13,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            3,
            4
          ],
          "cumulative": 66,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 89,
          "score": 23,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 102,
          "score": 13,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            3,
            "-"
          ],
          "cumulative": 105,
          "score": 3,
          "pins": 3,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            4
          ],
          "cumulative": 112,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 112,
      "pins": 80,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6.5,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 2,
        "spares": {
          "total": 3,
          "single": 0,
          "multiple": 3
        },
        "misses": 3,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2024-12-01:2",
      "date": {
        "year": 2024,
        "month": 12,
        "day": 1
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            1,
            2
          ],
          "cumulative": 12,
          "score": 3,
          "pins": 3,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 23,
          "score": 11,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            1,
            5
          ],
          "cumulative": 29,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 44,
          "score": 15,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            5,
            3
          ],
          "cumulative": 52,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 59,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            5
          ],
          "cumulative": 67,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            4
          ],
          "cumulative": 75,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "/",
            7
          ],
          "cumulative": 92,
          "score": 17,
          "pins": 17,
          "split": false,
          "spare": "multiple",
          "open": false
        }
      ],
      "score": 92,
      "pins": 86,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 5.1,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 3,
          "single": 2,
          "multiple": 1
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2024-12-08:1",
      "date": {
        "year": 2024,
        "month": 12,
        "day": 8
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 6,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 13,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 22,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 30,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 38,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            "/"
          ],
          "cumulative": 54,
          "score": 16,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 63,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 71,
          "score": 8,
          "pins": 8,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "-"
          ],
          "cumulative": 74,
          "score": 3,
          "pins": 3,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            4
          ],
          "cumulative": 82,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 82,
      "pins": 76,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6.3,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 7,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2024-12-08:2",
      "date": {
        "year": 2024,
        "month": 12,
        "day": 8
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            "X"
          ],
          "cumulative": 19,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 28,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            5,
            1
          ],
          "cumulative": 34,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 47,
          "score": 13,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            3,
            2
          ],
          "cumulative": 52,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 61,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 70,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 79,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 88,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 97,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 97,
      "pins": 85,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6.9,
        "splits": {
          "total": 2,
          "converted": 0
        },
        "strikes": 1,
        "spares": {
          "total": 1,
          "single": 1,
          "multiple": 0
        },
        "misses": 0,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2025-01-05:1",
      "date": {
        "year": 2025,
        "month": 1,
        "day": 5
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            5,
            4
          ],
          "cumulative": 9,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            6
          ],
          "cumulative": 18,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 27,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 36,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 55,
          "score": 19,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            4,
            5
          ],
          "cumulative": 64,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 73,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 82,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 90,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            3
          ],
          "cumulative": 99,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 99,
      "pins": 90,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6.5,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 1,
        "spares": {
          "total": 0,
          "single": 0,
          "multiple": 0
        },
        "misses": 2,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2025-01-05:2",
      "date": {
        "year": 2025,
        "month": 1,
        "day": 5
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            3,
            "/"
          ],
          "cumulative": 18,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 26,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "-"
          ],
          "cumulative": 35,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 41,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 49,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            1,
            4
          ],
          "cumulative": 54,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            4,
            2
          ],
          "cumulative": 60,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            9,
            "/"
          ],
          "cumulative": 78,
          "score": 18,
          "pins": 10,
          "split": false,
          "spare": "single",
          "open": false
        },
        {
          "outcome": [
            8,
            1
          ],
          "cumulative": 87,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/",
            8
          ],
          "cumulative": 105,
          "score": 18,
          "pins": 18,
          "split": false,
          "spare": "multiple",
          "open": false
        }
      ],
      "score": 105,
      "pins": 89,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6.2,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 0,
        "spares": {
          "total": 3,
          "single": 1,
          "multiple": 2
        },
        "misses": 3,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2025-02-16:1",
      "date": {
        "year": 2025,
        "month": 2,
        "day": 16
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            6,
            1
          ],
          "cumulative": 7,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 15,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            1
          ],
          "cumulative": 23,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 30,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "-"
          ],
          "cumulative": 33,
          "score": 3,
          "pins": 3,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            "/"
          ],
          "cumulative": 50,
          "score": 17,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 57,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            "-"
          ],
          "cumulative": 63,
          "score": 6,
          "pins": 6,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 90,
          "score": 27,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "X",
            7,
            1
          ],
          "cumulative": 108,
          "score": 18,
          "pins": 18,
          "split": false,
          "spare": false,
          "open": false
        }
      ],
      "score": 108,
      "pins": 84,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6.7,
        "splits": {
          "total": 0,
          "converted": 0
        },
        "strikes": 2,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 5,
        "fouls": 0
      }
    },
    {
      "id": "Calvin:2025-02-16:2",
      "date": {
        "year": 2025,
        "month": 2,
        "day": 16
      },
      "user": "Calvin",
      "ball": "Unknown",
      "oilPattern": "Typical House Shot",
      "frames": [
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 7,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 16,
          "score": 9,
          "pins": 9,
          "split": "yes",
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            2
          ],
          "cumulative": 25,
          "score": 9,
          "pins": 9,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "-"
          ],
          "cumulative": 32,
          "score": 7,
          "pins": 7,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            8,
            "-"
          ],
          "cumulative": 40,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            3,
            2
          ],
          "cumulative": 45,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            6,
            2
          ],
          "cumulative": 53,
          "score": 8,
          "pins": 8,
          "split": false,
          "spare": false,
          "open": true
        },
        {
          "outcome": [
            7,
            "/"
          ],
          "cumulative": 73,
          "score": 20,
          "pins": 10,
          "split": false,
          "spare": "multiple",
          "open": false
        },
        {
          "outcome": [
            "X"
          ],
          "cumulative": 88,
          "score": 15,
          "pins": 10,
          "split": false,
          "spare": false,
          "open": false
        },
        {
          "outcome": [
            "-",
            5
          ],
          "cumulative": 93,
          "score": 5,
          "pins": 5,
          "split": false,
          "spare": false,
          "open": true
        }
      ],
      "score": 93,
      "pins": 78,
      "perfect": false,
      "stats": {
        "avgFirstBallPinfall": 6.2,
        "splits": {
          "total": 1,
          "converted": 0
        },
        "strikes": 1,
        "spares": {
          "total": 1,
          "single": 0,
          "multiple": 1
        },
        "misses": 4,
        "fouls": 0
      }
    }
  ],
  "leagues": []
}